import React, { Fragment } from "react";
import { GridRowModes } from "@mui/x-data-grid";
import { InputButton } from "components/UI";
//UTIL
import { messageDisplay, responseErrors, responseSuccess } from "utils";
//Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
// TYPE
import { ModeAction, MSG_TYPE } from "state/enum";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
import { PAGINATION, BUTTON_VALUE, ROW_PER_PAGE, FIRST_PAGE } from "shared/constants";

//API
import { useMonthlyDeleteMutation, useMonthlySearchMutation } from "shared/services/inventory-forecast";
import { GENERATE_STATUS } from "../constants/monthly.type";
import sleep from "shared/hooks/Sleep";
import transformResponse from "../hooks/transformData";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function ActionTableBar(props) {
  const {
    form,
    modeList,
    rows,
    setRows,
    columns,
    setMode,
    setSearchForm,
    onSearch,
    setOnSearch,
    rowModesModel,
    setRowModesModel,
    rowSelectionModel,
    setRowSelectionModel,
    pagination: { pageNumber, setPageNumber, setPagination },
    stateButton: { addBtn, editBtn, deleteBtn },
    setMsg: { setMsgError, setMsgAlert },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const confirmDialogCtx = useConfirmDialogContext();
  const monthlyDelete = useMonthlyDeleteMutation();
  const searchMonthly = useMonthlySearchMutation();
  const handleAdd = () => {
    setSearchForm(old => ({
      ...old,
      [API_MONTHLY.FILE]: {},
      [API_MONTHLY.ORG_FILE_NAME]: "",
    }));
    let maxNo = -1;
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    const field = {};
    columns.forEach(column => {
      if (column.field === "rowNumber") {
        maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
        field[column.field] = rows.length ? maxNo + 1 : maxNo;
      } else {
        field[column.field] = "";
      }
    });

    setRows(prev => [
      ...prev,
      {
        ...field,
        isNew: true,
      },
    ]);

    const maxNoModel = rows.length ? maxNo + 1 : maxNo;

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNoModel]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNoModel]);
  };
  // ? HANDLE BUTTON EDIT
  const handleEdit = () => {
    setMsgError([]);
    setMsgAlert([]);
    setMode(ModeAction.EDIT);
    setSearchForm(old => ({
      ...old,
      [API_MONTHLY.FILE]: {},
      [API_MONTHLY.ORG_FILE_NAME]: "",
    }));

    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      return (tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      });
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
    }
  };
  const handleDelete = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    try {
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      if (row.useSts === GENERATE_STATUS.GENERATED) {
        // MDN92009AERR: Forecast data of Forecast Month 01/2024 already generated.
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN92009AERR",
          msg: ["Forecast Month", `${row?.forecastMonth}`],
        });
        setMsgError([msg]);
        return;
      }

      const body = {
        [API_MONTHLY.DATA_TYPE_CD]: row.dataTypeCd,
        [API_MONTHLY.UPDATE_BY]: form.userId,
        [API_MONTHLY.UPDATE_DATE]: row.updateDt,
      };
      const fileUploadId = row.fileUploadId;
      startLoading();

      const response = await monthlyDelete(fileUploadId, body);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);

      await sleep(5000);
      const bodySearch = {
        [API_MONTHLY.FORECAST_TYPE_CD]: form?.forecastTypeCd,
        [API_MONTHLY.MODE_CD]: form?.modeCd ? modeList.find(v => v.cd === form?.modeCd).value : "",
        [API_MONTHLY.FORECAST_MONTH]: form?.forecastMonth,
        [API_MONTHLY.DATA_TYPE_CD]: form?.dataTypeCd,
        [API_MONTHLY.UPLOAD_STATUS_CD]: form?.uploadStatusCd,
        [API_MONTHLY.UPLOAD_COMPANY_ABBR]: form.companyAbbr,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchMonthly(bodySearch);
      stopLoading();

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      if (!searchData?.result?.data?.length) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowModesModel({});
        setRowSelectionModel([]);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setRows([]);
        return false;
      }
      const data = transformResponse(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      window.scrollTo(0, 0);
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Fragment>
      <InputButton
        id="btn_add"
        value={BUTTON_VALUE.ADD}
        onClick={handleAdd}
        disabled={addBtn}
        onKeyDown={e => {
          if (!onSearch && e.key === "Tab") {
            e.preventDefault();
            document.getElementById("select-forecast-type").focus();
          }
        }}
        name={`${functionId}Add`}
      />
      <InputButton
        id="btn_edit"
        value={BUTTON_VALUE.EDIT}
        onClick={handleEdit}
        disabled={editBtn}
        name={`${functionId}Edit`}
      />
      <InputButton
        id="btn_delete"
        value={BUTTON_VALUE.DELETE}
        onClick={handleDelete}
        disabled={deleteBtn}
        name={`${functionId}Delete`}
      />
    </Fragment>
  );
}
