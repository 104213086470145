import { Photo } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  EditableDatePicker,
  EditableTextField,
  InputButton,
  InputEditTableCurrency,
  InputEditTableDropDown,
  InputEditTableFile,
  InputEditTableText,
} from "components/UI";
import dayjs from "dayjs";
import { MessageType, ModeAction } from "state/enum";
import { findObject, formatCurrency } from "utils";
import { isEmpty } from "lodash";

export default function useColumnRPackageType(
  rows,
  mode,
  ownerData,
  categoryData,
  rowSelectionModel,
  handleUploadPic,
  handleModalImage,
  handleModalPrice,
  currentDate
) {
  function getTextWidth(text, size) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    return context.measureText(text).width > size;
  }

  return [
    {
      field: "rowNumber",
      sortable: false,
      headerName: "No",
      align: "right",
      minWidth: 50,
      width: 50,
      editable: false,
      renderHeader: () => <div></div>,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    {
      field: "rPkgOwnerCompId",
      sortable: false,
      headerAlign: "center",
      align: "left",
      headerName: "R-Package Owner",
      minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 120 : 100,
      editable: true,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["R-Package Owner"],
        },
      ],
      renderHeader: () => <div></div>,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <InputEditTableDropDown
              {...params}
              required
              autoFocus
              memu={ownerData?.map(val => ({
                key: val.rPkgOwnerCompId,
                value: val.rPkgOwnerCompAbbr,
              }))}
              placeholder="<Select>"
              defaultValue=""
            />
          );
        } else {
          const name =
            params.value &&
            findObject({
              data: ownerData,
              value: params.value,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            });
          return <p>{name}</p>;
        }
      },
      renderCell: params => {
        const name = findObject({
          data: ownerData,
          value: params.value,
          property: "rPkgOwnerCompId",
          field: "rPkgOwnerCompAbbr",
        });

        return <p>{name}</p>;
      },
      flex: 1,
    },
    {
      field: "categoryCd",
      sortable: false,
      headerAlign: "center",
      align: "left",
      headerName: "R-Package Category",
      type: "select",
      minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 140 : 100,
      editable: true,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["R-Package Category"],
        },
      ],
      renderHeader: () => <div></div>,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <InputEditTableDropDown
              {...params}
              required
              memu={categoryData?.map(val => ({
                key: val.cd,
                value: val.value,
              }))}
              placeholder="<Select>"
              defaultValue=""
            />
          );
        } else {
          const name = findObject({
            data: categoryData,
            value: params.value,
            property: "cd",
            field: "value",
          });
          return <p>{name}</p>;
        }
      },
      renderCell: params => {
        const name = findObject({
          data: categoryData,
          value: params.value,
          property: "cd",
          field: "value",
        });
        return <p>{name}</p>;
      },
      flex: 1,
    },
    {
      field: "rPkgType",
      sortable: false,
      headerAlign: "center",
      align: "left",
      headerName: "R-Package Type",
      minWidth: 100,
      editable: true,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["R-Package Type"],
        },
      ],
      renderHeader: () => <div></div>,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <InputEditTableText
              {...params}
              required
              maxLength={5}
              // specialCharsRegex change by surasith
              regularExp={/^[a-zA-Z0-9_]*$/}
              style={{ textTransform: "uppercase" }}
            />
          );
        } else {
          return params?.value;
        }
      },
      renderCell: params => {
        return params?.value;
      },
      flex: 1,
    },
    {
      field: "rPkgTypeId",
    },
    {
      field: "rPkgDesc",
      sortable: false,
      headerAlign: "center",
      align: "left",
      headerName: "R-Package Type Description",
      minWidth: 180,
      editable: true,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["R-Package Type Description"],
        },
      ],
      renderHeader: () => <div></div>,
      renderEditCell: params => {
        return <InputEditTableText {...params} required maxLength={30} autoFocus={true} regularExp={/^.*$/} />;
      },
      renderCell: params => {
        if (getTextWidth(params?.value, 160))
          return (
            <Tooltip style={{ wordBreak: "break-word" }} title={`${params?.value}`}>
              {params?.value}
            </Tooltip>
          );
        else return <div style={{ wordBreak: "break-word" }}>{params?.value}</div>;
      },
      flex: 1,
    },
    {
      field: "weight",
      sortable: false,
      headerName: "Weight (Kg)",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Weight"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Weight", "0"],
        },
      ],
      renderHeader: () => <div></div>,
      renderEditCell: params => <InputEditTableCurrency {...params} required decimalScale={3} maxLimit={10000} />,
      renderCell: params => {
        return formatCurrency({ number: params?.value, digits: 3 });
      },
      flex: 1,
    },
    {
      field: "asbLength",
      sortable: false,
      headerName: "Length",
      type: "string",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      width: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Assembly Size(mm) Length"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Assembly Size(mm) Length", "0"],
        },
      ],
      renderEditCell: params => {
        return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
      },
      renderCell: params => {
        return params?.value;
      },
      flex: 1,
    },
    {
      field: "asbWidth",
      sortable: false,
      headerName: "Width",
      type: "text",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      width: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Assembly Size(mm) Width"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Assembly Size(mm) Width", "0"],
        },
      ],
      renderEditCell: params => {
        return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
      },
      renderCell: params => params?.value,
      flex: 1,
    },
    {
      field: "asbHeight",
      sortable: false,
      headerName: "Height",
      type: "string",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      width: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Assembly Size(mm) Height"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Assembly Size(mm) Height", "0"],
        },
      ],
      renderEditCell: params => {
        return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
      },
      renderCell: params => params?.value,
      flex: 1,
    },
    {
      field: "pic",
      sortable: false,
      headerName: "Picture",
      minWidth: 100,
      width: 100,
      editable: true,
      align: "center",
      headerAlign: "center",
      type: "actions",
      renderEditCell: params => {
        return <InputEditTableFile {...params} uploadProcess={(type, file) => handleUploadPic(type, file, 'Picture(Assembly)')} mode={mode} />;
      },
      getActions: params => [
        <>
          {params.row.pic && (
            <GridActionsCellItem
              style={{ alignContent: "center" }}
              key={0}
              icon={<Photo />}
              onClick={e => {
                e.preventDefault();
                handleModalImage(params.row, "asb");
              }}
            />
          )}
        </>,
      ],
      flex: 1,
    },
    {
      field: "dsbLength",
      sortable: false,
      headerName: "Length",
      type: "string",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      width: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Dis-assembly Size(mm) Length"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Dis-assembly Size(mm) Length", "0"],
        },
      ],
      renderEditCell: params => {
        return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
      },
      renderCell: params => {
        return params?.value;
      },
      flex: 1,
    },
    {
      field: "dsbWidth",
      sortable: false,
      headerName: "Width",
      type: "string",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      width: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Dis-assembly Size(mm) Width"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Dis-assembly Size(mm) Width", "0"],
        },
      ],
      renderEditCell: params => {
        return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
      },
      renderCell: params => {
        return params?.value;
      },
      flex: 1,
    },
    {
      field: "dsbHeight",
      sortable: false,
      headerName: "Height",
      type: "string",
      editable: true,
      align: "right",
      headerAlign: "center",
      minWidth: 100,
      width: 100,
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Dis-assembly Size(mm) Height"],
        },
        {
          type: MessageType.MORETHAN_ZERO,
          key: ["Dis-assembly Size(mm) Height", "0"],
        },
      ],
      renderEditCell: params => {
        return <InputEditTableCurrency {...params} required maxLimit={1000000} />;
      },
      renderCell: params => {
        return params?.value;
      },
      flex: 1,
    },
    {
      field: "dsbPic",
      sortable: false,
      headerName: "Picture",
      minWidth: 100,
      width: 100,
      editable: true,
      align: "center",
      headerAlign: "center",
      type: "actions",
      renderEditCell: params => {
        return <InputEditTableFile {...params} uploadProcess={(type, file) => handleUploadPic(type, file, 'Picture(Dis-assembly)')} />;
      },
      getActions: params => [
        <>
          {params.row.dsbPic && (
            <GridActionsCellItem
              style={{ alignContent: "center" }}
              key={0}
              icon={<Photo />}
              onClick={e => {
                e.preventDefault();
                handleModalImage(params.row, "dsb");
              }}
            />
          )}
        </>,
      ],
      flex: 1,
    },
    {
      field: "price",
      sortable: false,
      minWidth: 120,
      headerName: "Price",
      editable: true,
      align: "center",
      headerAlign: "center",
      renderHeader: () => <div></div>,
      renderEditCell: params => {
        const findRow = rows.find(v => v.rowNumber === params.id).currencys;
        if (findRow.length) {
          return (
            <div
              style={{
                color: params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black",
                cursor: "pointer",
                textDecoration: params.row.rowNumber === rowSelectionModel[0] ? "underline" : "none",
              }}
              onClick={() => {
                if (params.row.rowNumber === rowSelectionModel[0]) {
                  handleModalPrice(params.id, params.row);
                }
              }}>
              {findRow.map((val, i) => {
                return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
              })}
            </div>
          );
        }
        return (
          <InputButton
            value={"Detail"}
            color="info"
            size="small"
            style={{ textTransform: "none", margin: "auto" }}
            onClick={() => {
              if (params.row.rowNumber === rowSelectionModel[0]) {
                handleModalPrice(params.id, params.row);
              }
            }}
            minWidth="80px"
          />
        );
      },
      renderCell: params => {
        const findRow = rows.find(v => v.rowNumber === params.id).currencys;

        if (ModeAction.VIEW === mode) {
          if (findRow.length > 0) {
            return (
              <div
                style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  handleModalPrice(params.id, params.row);
                }}>
                {findRow.map((val, i) => {
                  return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                })}
              </div>
            );
          }
          return (
            <InputButton
              value={"Detail"}
              color="info"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => {
                handleModalPrice(params.id);
              }}
              minWidth="80px"
            />
          );
        } else {
          if (findRow.length) {
            return (
              <div
                style={{
                  color: params.row.rowNumber === rowSelectionModel[0] ? "blue" : "black",
                  cursor: "pointer",
                  textDecoration: params.row.rowNumber === rowSelectionModel[0] ? "underline" : "none",
                }}
                onClick={() => {
                  if (params.row.rowNumber === rowSelectionModel[0]) {
                    handleModalPrice(params.id, params.row);
                  }
                }}>
                {findRow.map((val, i) => {
                  return <div key={i}>{`${val.price}(${val.currentCd})`}</div>;
                })}
              </div>
            );
          }
          return (
            <InputButton
              value={"Detail"}
              color="info"
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => {
                if (params.row.rowNumber === rowSelectionModel[0]) {
                  handleModalPrice(params.id);
                }
              }}
              minWidth="80px"
            />
          );
        }
      },
      flex: 1,
    },
    {
      field: "color",
      sortable: false,
      minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 150 : 120,
      headerName: "Color",
      editable: true,
      align: "left",
      headerAlign: "center",
      rule: [
        {
          type: MessageType.EMPTY,
          key: ["Color"],
        },
      ],
      renderHeader: () => <div></div>,
      renderEditCell: params => {
        return <EditableTextField {...params} required maxLength={20} onUpperCase={true} />;
      },
      renderCell: params => {
        if (getTextWidth(params?.value, (mode === ModeAction.EDIT || mode === ModeAction.ADD ? 150 : 120) - 20))
          return (
            <Tooltip style={{ wordBreak: "break-word" }} title={`${params?.value}`}>
              {params?.value}
            </Tooltip>
          );
          else return <div style={{ wordBreak: "break-word" }}>{params?.value}</div>;
      },
      flex: 1,
    },
    {
      field: "effDtFrom",
      minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 180 : 120,
      sortable: false,
      headerName: "From",
      editable: true,
      align: "center",
      headerAlign: "center",
      valueGetter: params => {
        if (!params.value) return "";
        return dayjs(params.value, "YYYY-MM-DD").format("DD/MM/YYYY");
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableDatePicker {...params} required fullWidth />;
        } else {
          if(dayjs(params?.formattedValue, "DD/MM/YYYY").isBefore(dayjs())) return params.value
          return <EditableDatePicker {...params} disabled={dayjs(params?.formattedValue, "DD/MM/YYYY").isBefore(dayjs()) && isEmpty(params?.row?.effDtTo)} required value={params.value} fullWidth />;
        }
      },
      flex: 1,
    },
    {
      field: "effDtTo",
      minWidth: mode === ModeAction.EDIT || mode === ModeAction.ADD ? 180 : 120,
      sortable: false,
      headerName: "To",
      editable: true,
      align: "center",
      headerAlign: "center",
      valueGetter: params => {
        if (!params.value) return "";
        return dayjs(params.value, "YYYY-MM-DD").format("DD/MM/YYYY");
      },
      renderEditCell: params => {
        return <EditableDatePicker {...params} autoFocus={false} value={params.value} />;
      },
      flex: 1,
    },
    {
      field: "currencys",
      rule: [
        {
          type: MessageType.LENGTH,
          key: ["Price"],
        },
      ],
    },
  ];
}
