import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, callMsGraph } from "shared/libs/azure-msal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginInfoTest } from "shared/services/auth/auth.service";
import { setAuthenticated, setUser, setRole } from "shared/stores/reducers/authSlice";
export function Profile({ children }) {
  const authRequest = {
    ...loginRequest,
  };
  const dispatch = useDispatch();
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const getUserInfo = LoginInfoTest();
  const navigate = useNavigate();

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then(async response => {
          setGraphData(response);
          dispatch(setAuthenticated(true));

          const { result: userInfo } = await getUserInfo(
            response.id ? response.id.trim() : "",
            response.givenName ? response.givenName : ""
          );

          if (userInfo?.role.length > 0) {
            const bodyUserInfo = {
              ...response,
              userName: userInfo?.name,
              country: userInfo.country,
              companyAbbr: userInfo.companyCd,
            };
            dispatch(setUser(bodyUserInfo));

            dispatch(setRole(userInfo?.role));
            localStorage.setItem("appItem", userInfo?.role);
          } else {
            navigate("error");
          }
        })
        .catch(e => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              scopes: [process.env.REACT_APP_SCOPES],
              account: instance.getActiveAccount(),
            });
          }
        });
    }

    console.log("graphData", graphData);
    // if(graphData){
    //     window.location.href = 'https://myapplications.microsoft.com?tenant=f6cb9261-8a3a-4261-979f-50eeb15b4984&userid=${graphData?.id}';
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, graphData, instance]);

  console.log("authRequest", authRequest);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      // errorComponent={ErrorComponent}
      // loadingComponent={Loading}
    >
      <Paper>{children}</Paper>;
    </MsalAuthenticationTemplate>
  );
}
