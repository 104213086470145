import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Dialog, DialogContent, Grid, Divider, Checkbox, Stack } from "@mui/material";
import { AlertMessage, DatePickerField, InputButton, InputDropDown, InputText } from "components/UI";
import "./ModalContainerGroup.css";
import { MessageType, ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { findObject, messageTypeDisplay, responseErrors } from "utils";
import { omit } from "lodash";

import {
  useContainerGroupGetApmcPlantQuery,
  useContainerGroupGetContainerTypeModalQuery,
  useContainerGroupGetWarehouseQuery,
  useContainerGroupGetRPackageTypeQuery,
  useContainerGroupCreateMutation,
  useContainerGroupUpdateMutation,
} from "shared/services/master";
import { userProfile } from "constant";
import InputCurrency from "components/UI/InputCurrency";
import { validationSearchForm } from "utils/validation";
import { Link } from "react-router-dom";
import { Footer, HeaderContentBar } from "components/Layout";
import _ from "lodash";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { COMMON_ENUM } from "shared/constants";
export function ModalContainerGroup({
  open = true,
  setOpen,
  // initialData = [],
  mode,
  setMode,
  rowNumber = 0,
  rows,
  // setRows,
  setRowModesModel,
  setRowSelectionModel,
  setMsgAlertMainScreen,
  refetchSearch,
  modalForm,
  setModalForm,
  // apmcData,
  refetchContainerType,
  refetchApmcData,
}) {
  const userInfo = useSelector(state => state.auth.user);
  const dateTime = useSelector(state => state.common.dateTime);
  const confirmDialogCtx = useConfirmDialogContext();

  const dialogRef = useRef(null);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [rPackageTypeCheckboxList, setRPackageTypeCheckboxList] = useState([]);
  const [warehouseCheckboxList, setWarehouseCheckboxList] = useState([]);
  const [tempRPkgTypeId, setTempRPkgTypeId] = useState("");

  const handleInputScoreChange = (rPkgTypeId, value) => {
    setTempRPkgTypeId(rPkgTypeId);
    setRPackageTypeCheckboxList(prevState => {
      const updatedList = prevState?.map(item =>
        item.rPkgTypeId === rPkgTypeId ? { ...item, priority: value?.replace(/\D/, "") } : item
      );

      const totalPriority = updatedList?.reduce((acc, item) => {
        if (item.priority !== "") {
          return acc + parseInt(item.priority);
        } else {
          return acc;
        }
      }, 0);

      setModalForm(prev => ({ ...prev, score: totalPriority ?? 0 }));

      return updatedList;
    });
  };

  useEffect(() => {
    const totalPriority = _.sumBy(rPackageTypeCheckboxList, o => Number(o.priority));

    if (totalPriority > 9999) {
      const msg = messageTypeDisplay(MessageType.LESS_OR_EQUAL, [
        `The Total Score [${totalPriority.toLocaleString()}]`,
        "9,999 (4 digits)",
      ]);
      setTimeout(() => {
        alert(msg);
      }, 200);

      setRPackageTypeCheckboxList(prevState =>
        prevState.map((item, index) => (item.rPkgTypeId === tempRPkgTypeId ? { ...item, priority: "" } : item))
      );
    }
  }, [rPackageTypeCheckboxList]);

  // api

  const { data: apmcPlantData } = useContainerGroupGetApmcPlantQuery({
    dataOwner: userProfile.dataOwner,
  });

  const { data: rPackageTypeData } = useContainerGroupGetRPackageTypeQuery({
    dataOwner: userProfile.dataOwner,
  });

  const { data: warehouseData } = useContainerGroupGetWarehouseQuery({
    dataOwner: userProfile.dataOwner,
  });

  const { data: containerTypeDropdownData, refetch: getContainerTypeDropdownData } =
    useContainerGroupGetContainerTypeModalQuery({
      dataOwner: userProfile.dataOwner,
    });

  const createDataAsync = useContainerGroupCreateMutation();
  const editDataAsync = useContainerGroupUpdateMutation();

  // initial data
  useEffect(() => {
    setRPackageTypeCheckboxList(modalForm?.rPackageType);
    setWarehouseCheckboxList(modalForm?.warehouse);
  }, [open, mode, rowNumber]);

  // check disabled field
  const effDtFromDisabled = useMemo(() => {
    const oldEffDtFrom = findObject({
      data: rows,
      value: rowNumber,
      property: "rowNumber",
      field: "effDtFrom",
    });
    const currentDate = dayjs(dateTime, COMMON_ENUM.DATETIME_FORMAT);

    if (mode === ModeAction.VIEW) {
      return true;
    }
    if (modalForm?.effDtFrom && modalForm?.effDtFrom === oldEffDtFrom) {
      if (
        currentDate.isAfter(dayjs(modalForm?.effDtFrom, "DD/MM/YYYY"), "days") &&
        currentDate.isBefore(dayjs(modalForm?.effDtTo, "DD/MM/YYYY"), "days")
      ) {
        return false;
      }
      if (
        [ModeAction.VIEW, ModeAction.SELECTED].includes(mode) ||
        currentDate.isAfter(dayjs(modalForm?.effDtFrom, "DD/MM/YYYY"), "days")
      ) {
        return true;
      }
      return false;
    }
  }, [modalForm?.effDtFrom, mode]);

  const effDtToDisabled = useMemo(() => {
    const oldEffDtTo = findObject({
      data: rows,
      value: rowNumber,
      property: "rowNumber",
      field: "effDtTo",
    });
    const currentDate = dayjs(dateTime, COMMON_ENUM.DATETIME_FORMAT);

    if (mode === ModeAction.VIEW) {
      return true;
    }

    if (modalForm?.effDtTo && modalForm?.effDtTo === oldEffDtTo) {
      if (
        currentDate.isAfter(dayjs(modalForm?.effDtFrom, "DD/MM/YYYY"), "days") &&
        currentDate.isBefore(dayjs(modalForm?.effDtTo, "DD/MM/YYYY"), "days")
      ) {
        return false;
      }
      if (
        [ModeAction.VIEW, ModeAction.SELECTED].includes(mode) ||
        currentDate.isAfter(dayjs(modalForm?.effDtTo, "DD/MM/YYYY"), "days")
      ) {
        return true;
      }
      return false;
    }
  }, [modalForm?.effDtTo, mode]);

  // handle cancel click
  const handleCancelClick = async (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }

    setMsgAlert([]);
    setMsgError([]);
    setModalForm({
      apmc: "",
      containerGroupCode: "",
      containerTypeId: "",
      numRetry: "",
      score: "",
      effDtFrom: "",
      effDtTo: "",
      rPackageType: [],
      warehouse: [],
    });

    setRPackageTypeCheckboxList([]);
    setWarehouseCheckboxList([]);
    // setInputScoreValues({});

    setMode(ModeAction.VIEW);
    setOpen(false);
    setRowModesModel({});
    setRowSelectionModel([]);
  };

  // handle cancel close
  const handleCancelClose = async () => {
    setMsgAlert([]);
    setMsgError([]);
    setModalForm({
      apmc: "",
      containerGroupCode: "",
      containerTypeId: "",
      numRetry: "",
      score: "",
      effDtFrom: "",
      effDtTo: "",
      rPackageType: [],
      warehouse: [],
    });

    setRPackageTypeCheckboxList([]);
    setWarehouseCheckboxList([]);

    setMode(ModeAction.VIEW);
    setOpen(false);
    setRowModesModel({});
    setRowSelectionModel([]);
  };

  const handleAdd = async () => {
    try {
      const body = {
        dataOwner: userProfile.dataOwner,

        refCompanyId: findObject({
          data: apmcPlantData,
          value: modalForm?.apmc,
          property: "impExpCd",
          field: "companyId",
        }),
        refCompanyAbbr: findObject({
          data: apmcPlantData,
          value: modalForm?.apmc,
          property: "impExpCd",
          field: "companyAbbr",
        }),
        refPlantId: findObject({
          data: apmcPlantData,
          value: modalForm?.apmc,
          property: "impExpCd",
          field: "plantId",
        }),
        refImpExpCd: modalForm?.apmc,
        containerGroupCd: modalForm?.containerGroupCode,
        containerTypeId: modalForm?.containerTypeId,
        containerType: findObject({
          data: containerTypeDropdownData,
          value: modalForm?.containerTypeId,
          property: "containerTypeId",
          field: "containerType",
        }),
        numRetry: modalForm.numRetry ? parseInt(modalForm?.numRetry) : modalForm?.numRetry,
        effDtFrom: modalForm?.effDtFrom,
        effDtTo: modalForm?.effDtTo,
        createBy: userInfo.userName,
        rPkgType:
          rPackageTypeCheckboxList?.map(item => ({
            rPkgTypeId: item.rPkgTypeId,
            rPkgType: item.rPkgType,
            rPkgOwnerCompAbbr: userProfile.dataOwner,
            priority: parseInt(item.priority),
          })) || [],
        warehouse:
          warehouseCheckboxList?.map(item => ({
            whCompanyId: item.whCompanyId,
            whCompanyAbbr: item.whCompanyAbbr,
            whPlantId: item.whPlantId,
            whImpExpCd: item.whImpExpCd,
          })) || [],
      };

      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "refImpExpCd",
            type: MessageType.EMPTY,
            key: ["APMC"],
          },
          {
            field: "containerGroupCd",
            type: MessageType.EMPTY,
            key: ["Container Group Code"],
          },
          {
            field: "containerTypeId",
            type: MessageType.EMPTY,
            key: ["Container Type"],
          },
          {
            field: "numRetry",
            type: MessageType.NUMERIC_EMPTY,
            key: ["Number of Retry"],
          },
          {
            field: "effDtFrom",
            type: MessageType.EMPTY,
            key: ["Effective Date (From)"],
          },
          {
            field: "rPkgType",
            type: MessageType.ARRAY_EMPTY,
            key: ["R-Package Type"],
          },
          {
            field: "warehouse",
            type: MessageType.ARRAY_EMPTY,
            key: ["Warehouse"],
          },
          {
            field: "effDtFrom",
            type: MessageType.DATE_FORMAT_50,
            key: ["Effective Date (From)", "DD/MM/YYYY"],
          },
          {
            field: "effDtTo",
            type: MessageType.DATE_FORMAT_50,
            key: ["Effective Date (To)", "DD/MM/YYYY"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }

      await createDataAsync(body);
      const msg = messageTypeDisplay(MessageType.ADDED);

      setMsgAlert([msg]);
      setMsgAlertMainScreen([msg]);
      handleCancelClose();
      getContainerTypeDropdownData();
      refetchApmcData();
      refetchContainerType();

      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const getCommonKeys = (obj1, obj2) => {
    const commonKeys = Object.keys(obj1).filter(key => obj2.hasOwnProperty(key));
    const commonData = {};

    for (const key of commonKeys) {
      commonData[key] = obj1[key];
    }

    return omit(commonData, ["updateBy"]); //updateBy isn't change data
  };

  const compareIsObjectDataChange = (obj1, obj2) => {
    let changeData = 0;

    if (obj1.length !== obj2.length) return true;
    else {
      for (let i = 0; i < obj1.length; i++) {
        const ck = getCommonKeys(obj1[i], obj2[i]);
        for (const key of Object.keys(ck)) {
          if (obj1[key] !== obj2[key]) {
            changeData = changeData + 1;
          }
        }
      }
      return changeData > 0;
    }
  };

  const handleEdit = async () => {
    try {
      let isDataChange = false;

      const containerGroupId = rows.find(v => v.rowNumber === rowNumber).containerGroupId;

      const body = {
        dataOwner: userProfile.dataOwner,
        containerTypeId: modalForm?.containerTypeId,
        containerType: findObject({
          data: containerTypeDropdownData,
          value: modalForm?.containerTypeId,
          property: "containerTypeId",
          field: "containerType",
        }),
        numRetry: modalForm.numRetry ? parseInt(modalForm?.numRetry) : modalForm?.numRetry,
        effDtFrom: modalForm?.effDtFrom,
        effDtTo: modalForm?.effDtTo,
        updateBy: userInfo.userName,
        score: modalForm?.score,
        rPkgType:
          rPackageTypeCheckboxList?.map(item => ({
            containerGroupPtId: item?.containerGroupPtId,
            rPkgTypeId: item.rPkgTypeId,
            rPkgType: item.rPkgType,
            rPkgOwnerCompAbbr: userProfile.dataOwner,
            priority: parseInt(item.priority),
          })) || [],
        warehouse:
          warehouseCheckboxList?.map(item => ({
            containerGroupWhId: item?.containerGroupWhId,
            whCompanyId: item.whCompanyId,
            whCompanyAbbr: item?.whCompanyAbbr,
            whPlantId: item.whPlantId,
            whImpExpCd: item.whImpExpCd,
          })) || [],
      };

      const commonKeys = Object.keys(getCommonKeys(body, modalForm.defaultData));

      for (const key of commonKeys) {
        if (key === "rPkgType" || key === "warehouse") {
          if (compareIsObjectDataChange(body[key], modalForm.defaultData[key])) {
            isDataChange = true;
          }
        } else {
          if (body[key] !== modalForm.defaultData[key]) {
            isDataChange = true;
          }
        }
      }

      if (isDataChange) {
        const { isSuccess, errors } = validationSearchForm({
          data: body,
          rule: [
            {
              field: "containerTypeId",
              type: MessageType.EMPTY,
              key: ["Container Type"],
            },
            {
              field: "numRetry",
              type: MessageType.NUMERIC_EMPTY,
              key: ["Number of Retry"],
            },
            {
              field: "effDtFrom",
              type: MessageType.EMPTY,
              key: ["Effective Date (From)"],
            },
            {
              field: "rPkgType",
              type: MessageType.ARRAY_EMPTY,
              key: ["R-Package Type"],
            },
            {
              field: "warehouse",
              type: MessageType.ARRAY_EMPTY,
              key: ["Warehouse"],
            },
            {
              field: "effDtFrom",
              type: MessageType.DATE_FORMAT_50,
              key: ["Effective Date (From)", "DD/MM/YYYY"],
            },
            {
              field: "effDtTo",
              type: MessageType.DATE_FORMAT_50,
              key: ["Effective Date (To)", "DD/MM/YYYY"],
            },
          ],
        });

        if (!isSuccess) {
          setMsgError(errors);
          window.scrollTo(0, 0);
          return;
        }

        await editDataAsync(body, containerGroupId);
      }

      const msg = messageTypeDisplay(MessageType.UPDATED);
      setMsgAlert([msg]);
      setMsgAlertMainScreen([msg]);
      getContainerTypeDropdownData();
      refetchContainerType();
      refetchApmcData();

      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // handle save
  const handleSaveClick = async () => {
    setMsgAlert([]);
    setMsgError([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmSave" });

    if (!confirm) {
      return;
    }

    let response;
    if (ModeAction.ADD === mode) {
      response = await handleAdd();
    } else {
      response = await handleEdit();
    }

    if (!response) {
      await refetchSearch();
      return false;
    }

    await refetchSearch();
    handleCancelClose();
  };

  // handle change checkbox r-package type
  const handleChangeCheckboxRPackageType = (event, rPkgTypeId, rPkgType, priority) => {
    setRPackageTypeCheckboxList(prevState => {
      const isChecked = event.target.checked;

      if (isChecked) {
        return [...prevState, { rPkgTypeId, rPkgType, priority: priority || "" }];
      } else {
        const updateList = prevState.filter(item => item.rPkgTypeId !== rPkgTypeId);
        const totalPriority = updateList.reduce((acc, item) => {
          if (item.priority !== "") {
            return acc + parseInt(item?.priority);
          } else {
            return acc;
          }
        }, 0);
        setModalForm(prev => ({ ...prev, score: totalPriority }));
        return updateList;
      }
    });
  };

  // handle change checkbox warehouse
  const handleChangeCheckboxWarehouse = (event, whCompanyId, whCompanyAbbr, whPlantId, whImpExpCd) => {
    setWarehouseCheckboxList(prevState => {
      const isChecked = event.target.checked;

      if (isChecked) {
        return [...prevState, { whImpExpCd, whCompanyId, whCompanyAbbr, whPlantId }];
      } else {
        return prevState.filter(item => item.whImpExpCd !== whImpExpCd);
      }
    });
  };

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={handleCancelClick} sx={{ overflowY: "scroll" }}>
      <div ref={dialogRef}>
        <DialogContent>
          <Stack sx={{ display: "flex", alignItems: "flex-start" }}>
            <Link
              className="btn-close"
              href="#"
              color="#000"
              onClick={handleCancelClick}
              size="small"
              // sx={{ fontSize: "12px" }}
            >
              Close
            </Link>
          </Stack>
          <Stack sx={{ alignItems: "center" }}>
            <HeaderContentBar title="WDN91091 : Container Group Master Maintenance Sub Screen" />
          </Stack>
          <AlertMessage type={"warning"} message={msgError} />
          <AlertMessage type={"success"} message={msgAlert} />
          <Box className="box">
            <Grid
              container
              sx={{
                alignItems: "center",
              }}>
              <Grid item xs={2}>
                <strong>*APMC : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputDropDown
                  required
                  id="select_subscreen_apmc"
                  disabled={[ModeAction.VIEW, ModeAction.EDIT, ModeAction.SELECTED].includes(mode)}
                  memu={apmcPlantData?.map(val => ({
                    key: val.impExpCd,
                    value: val.companyAbbrImpExpCd,
                  }))}
                  value={modalForm?.apmc}
                  onChange={e =>
                    setModalForm(prev => ({
                      ...prev,
                      apmc: e.target.value,
                    }))
                  }
                  focus={mode === ModeAction.ADD}
                />
              </Grid>
              <Grid item xs={7} />

              <Grid item xs={2} sx={{ mt: 1 }}>
                <strong>*Container Group Code : </strong>
              </Grid>
              <Grid item xs={3} sx={{ mt: 1 }}>
                <InputText
                  id="select_subscreen_container_group_code"
                  fullWidth
                  required
                  disabled={[ModeAction.VIEW, ModeAction.EDIT, ModeAction.SELECTED].includes(mode)}
                  value={modalForm?.containerGroupCode}
                  onChange={e =>
                    setModalForm(prev => ({
                      ...prev,
                      containerGroupCode: e.target.value?.toUpperCase(),
                    }))
                  }
                  maxLength={10}
                  regularExp={/^[a-zA-Z0-9*]*$/}
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2}>
                <strong>*Container Type : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputDropDown
                  id="select_subscreen_container_type"
                  required
                  disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                  memu={containerTypeDropdownData?.map(val => ({
                    key: val.containerTypeId,
                    value: val.containerType,
                  }))}
                  value={modalForm?.containerTypeId}
                  onChange={e =>
                    setModalForm(prev => ({
                      ...prev,
                      containerTypeId: e.target.value,
                    }))
                  }
                  focus={mode === ModeAction.EDIT}
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2}>
                <strong>*Number of Retry : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputText
                  fullWidth
                  required
                  disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                  value={modalForm?.numRetry}
                  onChange={e => {
                    if (/^\d*$/.test(e.target.value) && e.target.value.length <= 3) {
                      setModalForm(prev => ({
                        ...prev,
                        numRetry: e.target.value,
                      }));
                    }
                  }}
                  style={{ textAlign: "right" }}
                  maxLength={3}
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2}>
                <strong>*Score : </strong>
              </Grid>
              <Grid item xs={3}>
                <InputCurrency
                  fullWidth
                  disabled
                  value={modalForm?.score ? modalForm?.score?.toString() : "0"}
                  onChange={e =>
                    setModalForm(prev => ({
                      ...prev,
                      score: e.value,
                    }))
                  }
                  style={{ textAlign: "right" }}
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} sx={{ mt: 1 }}>
                <strong>*Effective Date (From) : </strong>
              </Grid>

              <Grid item xs={3} sx={{ mt: 1 }}>
                <DatePickerField
                  id="input_effDtFrom"
                  fullWidth
                  disabled={effDtFromDisabled}
                  value={modalForm?.effDtFrom}
                  onChange={date => {
                    setModalForm(prev => ({ ...prev, effDtFrom: date }));
                  }}
                  required
                />
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} sx={{ mt: 1 }}>
                <strong>Effective Date (To) : </strong>
              </Grid>
              <Grid item xs={3} sx={{ mt: 1 }}>
                <DatePickerField
                  id="input_effDtTo"
                  fullWidth
                  disabled={effDtToDisabled}
                  value={modalForm?.effDtTo}
                  onChange={date => setModalForm(prev => ({ ...prev, effDtTo: date }))}
                />
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box className="box">
            <Grid
              container
              sx={{
                alignItems: "flex-start",
              }}>
              <Grid item xs={6} sx={{ p: 2 }}>
                <div style={{ marginBottom: 5 }}>
                  <strong>Select : </strong>
                </div>
                <div
                  style={{
                    display: "block",
                    height: "350px",
                    overflowY: "scroll",
                  }}>
                  <table className="table-container-group">
                    <thead>
                      <tr>
                        <td></td>
                        <td className="text-center">R-Package Type</td>
                        <td className="text-center">Score</td>
                      </tr>
                    </thead>
                    <tbody>
                      {rPackageTypeData?.map((val, i) => (
                        <tr key={i}>
                          <td className="text-center">
                            <Checkbox
                              name="chk_rptype"
                              disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                              checked={rPackageTypeCheckboxList?.some(item => item.rPkgTypeId === val.rPkgTypeId)}
                              onChange={e =>
                                handleChangeCheckboxRPackageType(e, val.rPkgTypeId, val.rPkgType, val.priority)
                              }
                            />
                          </td>
                          <td className="text-center rPkgType">
                            <span className="rPkgType">{val.rPkgType}</span>
                            <span className="rPkgTypeId" style={{ display: "none" }}>
                              {val.rPkgTypeId}
                            </span>
                          </td>
                          <td className="text-right score">
                            {[ModeAction.EDIT, ModeAction.ADD].includes(mode) ? (
                              <span>
                                <InputCurrency
                                  value={findObject({
                                    data: rPackageTypeCheckboxList,
                                    value: val.rPkgTypeId,
                                    property: "rPkgTypeId",
                                    field: "priority",
                                  })}
                                  style={{ textAlign: "right" }}
                                  maxLimit={10000}
                                  onChange={e => handleInputScoreChange(val.rPkgTypeId, e.value)}
                                  disabled={!rPackageTypeCheckboxList?.some(item => item.rPkgTypeId === val.rPkgTypeId)}
                                  required
                                />
                              </span>
                            ) : (
                              <span>
                                {findObject({
                                  data: rPackageTypeCheckboxList,
                                  value: val.rPkgTypeId,
                                  property: "rPkgTypeId",
                                  field: "priority",
                                })}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
              <Grid item xs={6} sx={{ p: 2 }}>
                <div style={{ marginBottom: 5 }}>
                  <strong>Select : </strong>
                </div>
                <div
                  style={{
                    display: "block",
                    height: "350px",
                    overflowY: "scroll",
                  }}>
                  <table className="table-container-group">
                    <thead>
                      <tr>
                        <td></td>
                        <td className="text-center">Warehouse</td>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouseData?.map((val, i) => (
                        <tr key={i}>
                          <td className="text-center">
                            <Checkbox
                              name="chk_warehouse"
                              disabled={[ModeAction.VIEW, ModeAction.SELECTED].includes(mode)}
                              checked={warehouseCheckboxList?.some(item => item.whImpExpCd === val.impExpCd)}
                              onChange={e =>
                                handleChangeCheckboxWarehouse(
                                  e,
                                  findObject({
                                    data: warehouseData,
                                    value: val.impExpCd,
                                    property: "impExpCd",
                                    field: "companyId",
                                  }),
                                  findObject({
                                    data: warehouseData,
                                    value: val.impExpCd,
                                    property: "impExpCd",
                                    field: "companyAbbr",
                                  }),
                                  findObject({
                                    data: warehouseData,
                                    value: val.impExpCd,
                                    property: "impExpCd",
                                    field: "plantId",
                                  }),
                                  val.impExpCd
                                )
                              }
                            />
                          </td>
                          <td className="warehouse">
                            <span className="impExpCd">{val.impExpCd}</span>
                            <span className="plantId" style={{ display: "none" }}>
                              {val.rPkgTypeId}
                            </span>
                            <span className="impExpCd" style={{ display: "none" }}>
                              {val.impExpCd}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box className="box">
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {[ModeAction.ADD, ModeAction.EDIT].includes(mode) && (
                  <>
                    <InputButton value={"Save"} onClick={handleSaveClick} size="small" name={"WDN91091Save"} />
                    <InputButton value={"Cancel"} onClick={handleCancelClick} size="small" name={"WDN91091Cancel"} />
                  </>
                )}
                {[ModeAction.VIEW, ModeAction.SELECTED].includes(mode) && (
                  <InputButton value={"Close"} onClick={handleCancelClose} size="small" noRoleRequired={true} />
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Box sx={{ mt: 3, position: "sticky" }}>
          <Footer width="100%" />
        </Box>
      </div>
    </Dialog>
  );
}
