import React, { Fragment, useState, useMemo, useEffect } from "react";
// Utils
import { isUndefined } from "lodash";
import { responseErrors } from "utils";
import { validationUploadFile } from "utils/validation";
// SHARE COMPONENT
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { Box, Grid } from "@mui/material";
// Hook
import { useColumn } from "./hooks/useColumn";
import useLocalStorage from "shared/hooks/useLocalStorage";
// COMPONENT
import FilterBar from "./components/FilterBar";
import ButtonBar from "./components/ButtonBar";
import Table from "./components/Table";
// API
import {
  useApmcSearchMutation,
  useGetForecastModeAscQuery,
  useGetApmcForecastDataTypeQuery,
  useGetForecastUploadStatusQuery,
  useApmcImporterExporterQuery,
} from "shared/services/inventory-forecast";
//TYPE
import { ModeAction, MessageType } from "state/enum";
import { API_NAME_APMC, DEFAULT_LOCAL_STATE, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { transformDataApmc } from "./hooks/transfromData";
import { useSelector } from "react-redux";
import { DEFAULT_VALUE } from "./constants/apmc.type";

export default function ApmcForecastScreen() {
  const { startLoading, stopLoading } = useLoadingContext();
  const userInfo = useSelector(state => state.auth.user);
  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);

  // API
  const apmcSearch = useApmcSearchMutation();

  // Form
  const [form, setForm] = useState({
    ...DEFAULT_VALUE,
    [API_NAME_APMC.COMPANY_ABBR]: userInfo.companyAbbr,
    [API_NAME_APMC.USER_ID]: userInfo.userName,
  });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);

  // ? Table

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  // API
  const { data: modeList } = useGetForecastModeAscQuery();
  const { data: dataTypeList } = useGetApmcForecastDataTypeQuery();
  const { data: uploadStatusList } = useGetForecastUploadStatusQuery();
  const { data: impExpList } = useApmcImporterExporterQuery(userInfo.companyAbbr);
  const [disable, setDisable] = useState(false);

  // ? Upload File
  const handleUploadFile = async (field, file, maxLength) => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (isUndefined(file)) {
        setForm(prev => ({
          ...prev,
          file: {},
          [API_NAME_APMC.ORG_FILE_NAME]: "",
        }));
        return true;
      }
      // validate
      const { isSuccess, errors } = validationUploadFile({
        file: file,
        rule: [
          {
            type: MessageType.TYPE_EXCEL,
            key: [file.name, "excel"],
          },
          {
            type: MessageType.FILE_NAME,
            key: [`Uploaded File Name`, maxLength],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      setForm(prev => ({
        ...prev,
        file: file,
        [API_NAME_APMC.ORG_FILE_NAME]: file.name,
      }));
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleLinkStatus = async (value, event) => {
    try {
      event.preventDefault();
      let apmcState;

      apmcState = {
        dataOwner: userProfile.dataOwner,
        moduleId: value?.moduleId,
        functionId: value?.functionId,
        statusId: "",
        userId: value?.uploadBy,
        appId: "",
        dateFrom: value?.uploadDt,
        dateTo: value?.uploadDt,
        levelId: "",
      };

      setLogDetail(apmcState);
      if (process.env.REACT_APP_SITE === "/") {
        window.open("/common/monitoring/log-monitoring-detail", "_blank");
      } else {
        window.open(
          `${process.env.REACT_APP_SITE ? process.env.REACT_APP_SITE + "/common/monitoring/log-monitoring-detail" : "/common/monitoring/log-monitoring-detail"}`,
          "_blank"
        );
      }
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  const columns = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useColumn({
        rows,
        mode,
        dataList: {
          modeList: modeList,
          dataTypeList: dataTypeList,
          uploadStatusList: uploadStatusList,
          impExpList: impExpList,
        },
        setDisable,
        disable,
        handleUploadFile,
        handleLinkStatus,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, mode, disable]
  );

  const getSearch = async (body, pageNumber) => {
    try {
      startLoading();
      const searchData = await apmcSearch(body);
      stopLoading();
      const data = transformDataApmc(searchData, pageNumber, ROW_PER_PAGE);
      return { searchData, data };
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };

  useEffect(() => {
    if (dataTypeList) dataTypeList.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
  }, [dataTypeList]);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN92020 : APMC Forecast Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <FilterBar
              form={form}
              setForm={setForm}
              mode={mode}
              setMode={setMode}
              setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
              dataList={{
                modeList: modeList,
                dataTypeList: dataTypeList,
                uploadStatusList: uploadStatusList,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBar
              form={form}
              setForm={setForm}
              mode={mode}
              setMode={setMode}
              rows={rows}
              setRows={setRows}
              columns={columns}
              setOnSearch={setOnSearch}
              setDisable={setDisable}
              loading={{ startLoading, stopLoading }}
              dataList={{
                impExpList: impExpList,
                modeList: modeList,
                dataTypeList: dataTypeList,
              }}
              getSearch={getSearch}
              setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
              pagination={{
                pagination: pagination,
                pageNumber: pageNumber,
                setPagination: setPagination,
                setPageNumber: setPageNumber,
              }}
              rowSelection={{
                rowSelectionModel: rowSelectionModel,
                setRowSelectionModel: setRowSelectionModel,
                rowModesModel: rowModesModel,
                setRowModesModel: setRowModesModel,
              }}
              functionId={"WDN92020"}
            />
          </Grid>

          {onSearch && (
            <Grid item xs={12}>
              <Table
                form={form}
                dataList={{
                  impExpList: impExpList,
                  dataTypeList: dataTypeList,
                  modeList: modeList,
                }}
                mode={mode}
                setMode={setMode}
                rows={rows}
                setRows={setRows}
                columns={columns}
                onSearch={onSearch}
                setOnSearch={setOnSearch}
                getSearch={getSearch}
                setMsg={{ setMsgAlert: setMsgAlert, setMsgError: setMsgError }}
                pagination={{
                  pagination: pagination,
                  pageNumber: pageNumber,
                  setPagination: setPagination,
                  setPageNumber: setPageNumber,
                }}
                rowSelection={{
                  rowSelectionModel: rowSelectionModel,
                  setRowSelectionModel: setRowSelectionModel,
                  rowModesModel: rowModesModel,
                  setRowModesModel: setRowModesModel,
                }}
                loading={{ startLoading, stopLoading }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}
