import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorPage = () => {
  const navigate = useNavigate();
  const site = process.env.REACT_APP_SITE ? `${process.env.REACT_APP_SITE}/` : "/";
  return (
    <Grid
      container
      direction="column"
      height={"100vh"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
      <center>
        <ErrorOutlineIcon sx={{ fontSize: 120 }} color="error" />
      </center>
      <Typography variant="h4" color="error" gutterBottom>
        Something went worng!
      </Typography>
      <Typography variant="h4" color="error" gutterBottom>
        Permission denied!, Please contact TASC.
      </Typography>
      <Button
        style={{ marginTop: "25px" }}
        width={"340px"}
        variant="contained"
        color="error"
        onClick={() => navigate(site)}>
        Try Again
      </Button>
    </Grid>
  );
};

export default ErrorPage;
