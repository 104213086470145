import { Grid } from "@mui/material";
import { CheckBox, DropDown, InputButton } from "components/UI";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import React from "react";
import { BUTTON_VALUE, COMMON_ENUM } from "shared/constants";
import {
  useExportInvoiceCancelInvoiceMutation,
  useExportInvoiceDownloadExcelMutation,
  // useExportInvoiceExpCompanyQuery,
  useExportInvoiceCheckActionTypeQueryMutation,
} from "shared/services/invoice";
import { ModeAction, MSG_TYPE } from "state/enum";
import { message, messageDisplay, responseErrors, responseSuccess } from "utils";
import _ from "lodash";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { useSelector } from "react-redux";

export default function ButtonAction(props) {
  const {
    form,
    setForm,
    mode,
    setMsg: { setMsgAlert, setMsgError },
    setOpenPopupSub,
    setOpenPopupCancelSub,
    dataList: { reportFormatData },
    rowSelection: { rowSelectionModel, setRowSelectionModel },
    rows,
    onSearch,
    refreshRowsTable,
    setFormSubScreen,
    isAPMCUser,
    functionId,
    setActionMode,
  } = props;
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const downloadExcel = useExportInvoiceDownloadExcelMutation();
  const cancelInvoice = useExportInvoiceCancelInvoiceMutation();
  const checkInvoiceCondition = useExportInvoiceCheckActionTypeQueryMutation();
  // const { data: exporterData } = useExportInvoiceExpCompanyQuery(userInfo.companyAbbr);

  const handleCheckTotalM3 = e => {
    setMsgError([]);
    setMsgAlert([]);
    setForm(prev => ({
      ...prev,
      totalsM3Flag: e.target.checked,
    }));
  };

  const handleDownloadExcel = async e => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (!rowSelectionModel.length) {
        alert("MDN95005AERR: A record must be selected to execute Generate Report operation.");
        return;
      }
      let nonCommInvHId = "";
      const formattedData = rowSelectionModel.map(val => {
        const row = rows.find(v => v.rowNumber === val);
        nonCommInvHId = row ? row.nonCommInvHId : "";
        return {
          dataOwner: userProfile.dataOwner,
          invoiceNo: row ? row.invNo : "",
          invoiceDt: row ? `${row.invDt.split("/")[2]}-${row.invDt.split("/")[1]}-${row.invDt.split("/")[0]}` : "",
          expCd: row ? row.expCd : "",
          impCd: row ? row.impCompanyCd : "",
        };
      });

      const body = {
        dataOwner: userProfile.dataOwner,
        nonCommInvHId: nonCommInvHId,
        userId: userInfo.userName,
        invoiceNo: formattedData.map(v => v.invoiceNo),
        // fileType: "Excel",
        fileType: _.isEmpty(form?.reportFormatCd) ? "" : form?.reportFormatCd === "PDF" ? "PDF" : "Excel",
        invoiceDt: formattedData.map(v => v.invoiceDt),
        expCd: formattedData.map(v => v.expCd),
        impCd: formattedData.map(v => v.impCd),
        isZip: false, //fix
        totalsM3Flag: form?.totalsM3Flag ? "Y" : "N",
      };
      startLoading();
      const { data } = await downloadExcel(body);
      waitAndDownloadExcel(60, data, setMsgError, stopLoading);
      stopLoading();
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleOpenModalCreateInvoice = e => {
    setMsgError([]);
    setMsgAlert([]);
    setActionMode({ actionMode: COMMON_ENUM.SC_MODE_CREATE_INVOICE });
    setOpenPopupSub({
      open: true,
      isEdit: false,
      isAPMCUser: isAPMCUser,
      data: [],
      actionType: COMMON_ENUM.SC_MODE_CREATE_INVOICE,
    });
  };

  const handleOpenModalEditInvoice = async e => {
    setMsgError([]);
    setMsgAlert([]);
    setActionMode({ actionMode: "EDIT" });

    if (rowSelectionModel.length > 1) {
      alert(message({ type: "singleRecord" }));
      setRowSelectionModel([]);
      return;
    }
    if (!rowSelectionModel.length) {
      const msg = messageDisplay({
        type: MSG_TYPE.ERROR,
        code: "MDN90025AERR",
        msg: [""],
      });
      setMsgError([msg]);
      return;
    }
    const selectedNo = rowSelectionModel[0];
    const row = rows.find(v => v.rowNumber === selectedNo);

    setFormSubScreen();
    setMsgError([]);
    // CommonEun.VIEW || CommonEun.EDIT
    const actionType = await checkInvoiceCondition(row.dataOwner, row.nonCommInvHId);
    setFormSubScreen(prev => ({
      ...prev,
      invNo: row.invNo,
      dataOwner: row.dataOwner,
      impCompanyCd: row.impCompanyCd,
      importerCompanyAbbr: row?.impCompanyAbbr,
      impCompanyId: row?.impCompanyId,
      exporterCd: row.expCd,
      exporterCompanyAbbr: row.expCompanyAbbr,
      exporterPlantId: row.expPlantId,
      carrierCompanyAbbr: row?.expBrokerCompanyAbbr ? row?.expBrokerCompanyAbbr : "",
      expBrokerCompanyId: row?.expBrokerCompanyId ? row?.expBrokerCompanyId : "",
      expBrokerCompanyAbbr: row?.expBrokerCompanyAbbr ? row?.expBrokerCompanyAbbr : "",
      etdDt: row.etdDt,
      updateDt: row?.updateDt,
      updateBy: row?.updateBy,
      nonComInvHId: row.nonCommInvHId,
      currencyCd: row.currencyCd,
      expEntryNo: row?.expEntryNo,
      expCustomsStkDt: row.expCustomsStkDt,
      nonComInvFlag: row.invType === "N" ? true : false,
      invType: "",
      shipmentEtd: actionType?.result === COMMON_ENUM.SC_MODE_VIEW ? "" : row.shipmentEtd,
      parkingMonth: "",
      shipmentCd: row.shipmentCd,
      vanningPlant: "",
      vesselName: row.vesselName,
    }));

    setOpenPopupSub({
      open: true,
      isEdit: true,
      isAPMCUser: isAPMCUser,
      data: row,
      actionType: actionType?.result,
    });
  };

  const handleOpenModalCancelVanningResult = e => {
    setMsgError([]);
    setMsgAlert([]);
    setOpenPopupCancelSub(true);
  };

  const handleCancelInvoice = async e => {
    setMsgError([]);
    setMsgAlert([]);
    if (!rowSelectionModel.length) {
      alert("MDN95008AERR: A record must be selected to execute Cancel Invoice operation.");
      return;
    }
    if (rowSelectionModel.length > 1) {
      setMsgError();
      alert(message({ type: "singleRecord" }));
      setRowSelectionModel([]);
      return;
    }
    const confirm = await confirmDialogCtx.success({
      msg: "MDN95032ACFM: Do you want to confirm to Cancel Invoice?",
    });
    if (!confirm) {
      return;
    }

    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN90024AERR",
          msg: [""],
        });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);

      const body = {
        dataOwner: row.dataOwner,
        loginUserCompanyAbbr: userInfo.companyAbbr,
        userId: userInfo.userName,
        updateDt: row.updateDt,
        updateBy: row.updateBy,
        shipmentCd: row.shipmentCd,
        exporterCd: row.expCd,
        importerCd: row.impCompanyCd,
        etdDt: row.etdDt,
        invoiceNo: row.invNo,
      };

      const nonCommInvHId = row.nonCommInvHId;
      startLoading();
      const response = await cancelInvoice(nonCommInvHId, body);
      stopLoading();
      const msgDelete = responseSuccess(response);
      setMsgAlert([msgDelete]);

      //Search new data
      await refreshRowsTable();
      return;
    } catch (error) {
      stopLoading();
      console.log(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={7}>
        {onSearch && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <strong>* Report Format :</strong>
            </Grid>
            <Grid item xs={3}>
              <DropDown
                id="select-report-format"
                fullWidth
                required
                defaultValue=""
                placeholder="<Select>"
                value={form?.reportFormatCd}
                onChange={e =>
                  setForm(old => ({
                    ...old,
                    reportFormatCd: e.target.value,
                  }))
                }
                menu={reportFormatData?.map(val => ({
                  key: val?.cd,
                  value: val?.value,
                }))}
                disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              />
            </Grid>
            <Grid item xs={6}>
              <InputButton id="generate-reports" value={BUTTON_VALUE.GENERATE_REPORTS} onClick={handleDownloadExcel} />
              <CheckBox
                id="checkbox-totals-m3-flag"
                placement="start"
                label="Total M3"
                sx={{
                  color: "#000",
                  fontFamily: "Kanit",
                  fontWeight: "bold",
                  ml: 1,
                }}
                checked={form?.totalsM3Flag}
                onChange={e => handleCheckTotalM3(e)}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={5}>
        <Grid container alignItems="flex-end" direction={"row"} spacing={1}>
          <Grid item xs={onSearch ? 2 : 4} />
          <Grid item xs={4} alignContent={"flex-end"}>
            <InputButton
              id="button-create-invoice"
              value={BUTTON_VALUE.CREATE_INVOICE}
              minWidth="100%"
              onClick={e => handleOpenModalCreateInvoice(e)}
            />
          </Grid>
          {onSearch && (
            <>
              <Grid item xs={2}>
                <InputButton
                  id="button-edit"
                  value={BUTTON_VALUE.EDIT}
                  minWidth="100%"
                  onClick={e => handleOpenModalEditInvoice(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <InputButton
                  id="button-cancel-invoice"
                  value={BUTTON_VALUE.CANCEL_INVOICE}
                  minWidth="100%"
                  onClick={handleCancelInvoice}
                  name={`${functionId}CancelInvoice`}
                />
              </Grid>
            </>
          )}
          <Grid item xs={onSearch ? 12 : 4} justifyContent="end" display="flex">
            <InputButton
              id="button-cancel-vanning-result"
              value={BUTTON_VALUE.CANCEL_VANNING_RESULT}
              onClick={handleOpenModalCancelVanningResult}
              style={{ marginRight: 0 }}
              minWidth={onSearch ? null : "100%"}
              onKeyDown={e => {
                if (!onSearch && e.key === "Tab") {
                  e.preventDefault();
                  document.getElementById("select-importer").focus();
                }
              }}
              name={`${functionId}CancelVanningResult`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
