import { API_ON_DEMAND_BATCH } from "shared/constants";
import { COLUMN_ON_DEMAND_BATCH } from "../constants/table.constant";
export default function useColumn() {
  return [
    // Col1
    {
      field: API_ON_DEMAND_BATCH.ROW_NUMBER,
      headerName: COLUMN_ON_DEMAND_BATCH.NO,
      headerAlign: "center",
      align: "left",
      minWidth: 50,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col2
    {
      field: API_ON_DEMAND_BATCH.PROJECT_CODE,
      headerName: COLUMN_ON_DEMAND_BATCH.PROJECT_CODE,
      headerAlign: "center",
      align: "left",
      // width: 150,
      maxWidth: 100,
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col3
    {
      field: API_ON_DEMAND_BATCH.BATCH_ID,
      headerName: COLUMN_ON_DEMAND_BATCH.BATCH_ID,
      headerAlign: "center",
      align: "left",
      // width: 200,
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col4
    {
      field: API_ON_DEMAND_BATCH.BATCH_NAME,
      headerName: COLUMN_ON_DEMAND_BATCH.BATCH_NAME,
      headerAlign: "center",
      align: "left",
      // width: 400,
      minWidth: 350,
      flex: 0.3,
      sortable: false,
      editable: false,
      renderCell: params => <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>{params.value}</div>,
    },
    // Col5
    {
      field: API_ON_DEMAND_BATCH.END_POINT,
      headerName: COLUMN_ON_DEMAND_BATCH.END_POINT,
      headerAlign: "center",
      align: "left",
      // width: 400,
      minWidth: 450,
      flex: 0.3,
      sortable: false,
      editable: false,
      renderCell: params => <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>{params.value}</div>,
    },
    // Col6
    {
      field: API_ON_DEMAND_BATCH.CREATE_BY,
      headerName: COLUMN_ON_DEMAND_BATCH.CREATE_BY,
      headerAlign: "center",
      align: "left",
      // width: 150,
      minWidth: 275,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    // Col7
    {
      field: API_ON_DEMAND_BATCH.CREATE_DATE,
      headerName: COLUMN_ON_DEMAND_BATCH.CREATE_DATE,
      headerAlign: "center",
      align: "left",
      // width: 250,
      minWidth: 150,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    // Col8
    {
      field: API_ON_DEMAND_BATCH.UPDATE_BY,
      headerName: COLUMN_ON_DEMAND_BATCH.UPDATE_BY,
      headerAlign: "center",
      align: "left",
      // width: 150,
      minWidth: 275,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    // Col9
    {
      field: API_ON_DEMAND_BATCH.UPDATE_DATE,
      headerName: COLUMN_ON_DEMAND_BATCH.UPDATE_DATE,
      headerAlign: "center",
      align: "left",
      // width: 250,
      minWidth: 150,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
  ];
}
