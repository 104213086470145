import React, { Fragment, useCallback } from "react";
//UTIL
import { responseErrors, messageDisplay, responseSuccess } from "utils";
// Share Component
import { DataGridTable, SubmitBar } from "components/UI";
// Type
import { ModeAction } from "state/enum";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
import { PAGINATION } from "shared/constants/table.constant";
//API
import { useMonthlyCreateMutation, useMonthlyUpdateMutation } from "shared/services/inventory-forecast";
import { MONTHLY_DATA_TYPE, UPLOAD_STATUS } from "../constants/monthly.type";
import { WEB_FUNCTION_ID } from "shared/constants";
import sleep from "shared/hooks/Sleep";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function BodySection({
  dataList: { modeList },
  form,
  rows,
  setRows,
  columns,
  pagination,
  setPagination,
  pageNumber,
  setPageNumber,
  onSearch,
  setOnSearch,
  mode,
  setMode,
  setMsg: { setMsgError, setMsgAlert },
  rowModesModel,
  setRowModesModel,
  rowSelectionModel,
  setRowSelectionModel,
  getSearch,
}) {
  const { startLoading, stopLoading } = useLoadingContext();
  // const userInfo = useSelector(state => state.auth.user);
  const monthlyCreate = useMonthlyCreateMutation();
  const monthlyUpdate = useMonthlyUpdateMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        [API_MONTHLY.FORECAST_TYPE_CD]: form.forecastTypeCd,
        [API_MONTHLY.MODE_CD]: form.modeCd ? modeList.find(v => v.cd === form.modeCd).value : "",
        [API_MONTHLY.FORECAST_MONTH]: form.forecastMonth,
        [API_MONTHLY.DATA_TYPE_CD]: form.dataTypeCd,
        [API_MONTHLY.UPLOAD_STATUS_CD]: form.uploadStatusCd,
        [API_MONTHLY.UPLOAD_COMPANY_ABBR]: form?.userCompanyAbbr,
        [PAGINATION.PAGE_NUMBER]: value,
      };
      startLoading();
      const { searchData, data } = await getSearch(body);
      stopLoading();
      if (!data.length) {
        setMsgError([messageDisplay({ type: "error", code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleCreate = async newRow => {
    try {
      // const body = {
      //   [API_MONTHLY.COMPANY_ABBR]: form?.companyAbbr,
      //   [API_MONTHLY.CREATE_BY]: form?.userId,
      //   [API_MONTHLY.MODE_CD]: !form?.modeCd ? "F" : form?.modeCd,
      //   [API_MONTHLY.FORECAST_TYPE_CD]: form?.forecastTypeCd,
      //   [API_MONTHLY.FORECAST_MONTH]: newRow?.forecastMonth,
      //   [API_MONTHLY.DATA_TYPE_CD]: newRow?.dataType,
      //   [API_MONTHLY.ORG_FILE_NAME]: form?.orgFileName,
      //   [API_MONTHLY.FUNCTION_ID]: WEB_FUNCTION_ID.FORECAST_MONTHLY,
      //   file: form?.file,
      // };
      // const { isSuccess, errors } = validationSearchForm({
      //   data: body,
      //   rule: [
      //     {
      //       field: API_MONTHLY.FORECAST_MONTH,
      //       type: MessageType.MONTH_INVALID_45,
      //       key: ["Forecast Month", "MM/YYYY"],
      //     },

      //     {
      //       field: API_MONTHLY.DATA_TYPE_CD,
      //       type: MessageType.EMPTY,
      //       key: ["Data Type"],
      //     },

      //     {
      //       field: API_MONTHLY.ORG_FILE_NAME,
      //       type: MessageType.EMPTY,
      //       key: ["File Name"],
      //     },
      //   ],
      // });
      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return false;
      // }
      const createData = new FormData();
      createData.append([API_MONTHLY.COMPANY_ABBR], form?.userCompanyAbbr);
      createData.append([API_MONTHLY.MODE_CD], !form?.modeCd ? "F" : form?.modeCd);
      createData.append([API_MONTHLY.FORECAST_TYPE_CD], form?.forecastTypeCd);
      createData.append([API_MONTHLY.FORECAST_MONTH], newRow?.forecastMonth);
      createData.append([API_MONTHLY.DATA_TYPE_CD], newRow?.dataType);
      createData.append([API_MONTHLY.CREATE_BY], form?.userId);
      createData.append([API_MONTHLY.FUNCTION_ID], WEB_FUNCTION_ID.FORECAST_MONTHLY);
      createData.append([API_MONTHLY.FILE], form?.file);

      const response = await monthlyCreate(createData);
      const msg = responseSuccess(response);
      // const msg = messageDisplay({
      //   type: MSG_TYPE.INFO,
      //   code: "MDN92016AINF",
      //   msg: ["Monthly Delivery Plan Data Upload & Validation"],
      // });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleUpdate = async row => {
    try {
      const fileUploadId = row.fileUploadId;
      // const body = {
      //   [API_MONTHLY.COMPANY_ABBR]: form.companyAbbr,
      //   [API_MONTHLY.MODE_CD]: form?.modeCd,
      //   [API_MONTHLY.FORECAST_TYPE_CD]: row.forecastType,
      //   [API_MONTHLY.FORECAST_MONTH]: row.forecastMonth,
      //   [API_MONTHLY.DATA_TYPE_CD]: row.dataTypeCd,
      //   [API_MONTHLY.UPDATE_BY]: form.userId,
      //   [API_MONTHLY.ORG_FILE_NAME]: form?.orgFileName,
      //   [API_MONTHLY.FUNCTION_ID]: WEB_FUNCTION_ID.FORECAST_MONTHLY,
      //   file: form?.file,
      // };
      // const { isSuccess, errors } = validationSearchForm({
      //   data: body,
      //   rule: [
      //     {
      //       field: API_MONTHLY.ORG_FILE_NAME,
      //       type: MessageType.EMPTY,
      //       key: ["File Name"],
      //     },
      //   ],
      // });
      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return false;
      // }

      const updateData = new FormData();
      updateData.append([API_MONTHLY.COMPANY_ABBR], form.userCompanyAbbr);
      updateData.append([API_MONTHLY.MODE_CD], form?.modeCd);
      updateData.append([API_MONTHLY.FORECAST_TYPE_CD], row.forecastType);
      updateData.append([API_MONTHLY.FORECAST_MONTH], row.forecastMonth);
      updateData.append([API_MONTHLY.DATA_TYPE_CD], row.dataTypeCd);
      updateData.append([API_MONTHLY.UPDATE_BY], form.userId);
      updateData.append([API_MONTHLY.FUNCTION_ID], WEB_FUNCTION_ID.FORECAST_MONTHLY);
      updateData.append([API_MONTHLY.FILE], form?.file);
      const response = await monthlyUpdate(fileUploadId, updateData);
      const msg = responseSuccess(response);
      // const msg = messageDisplay({
      //   type: MSG_TYPE.INFO,
      //   code: "MDN92016AINF",
      //   msg: ["Monthly Delivery Plan Data Upload & Validation"],
      // });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleProcessRowUpdateError = useCallback(error => {
    console.error(` children:${error.message}, severity: ${error}`);
  }, []);

  const processRowUpdate = async newRow => {
    let response;
    try {
      setMsgError([]);
      setMsgAlert([]);
      let updatedRow;
      startLoading();
      if (newRow.isNew) {
        response = await handleCreate(newRow);
        updatedRow = { ...newRow, isNew: false };
      } else {
        response = await handleUpdate(newRow);
        updatedRow = { ...newRow };
      }
      // throw to onProcessRowUpdate is error
      if (!response) {
        stopLoading();
        return false;
      }
      if (rows.length === 1 && ModeAction.ADD === mode) {
        stopLoading();
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowSelectionModel([]);
        setRowModesModel({});
        setRows([]);
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        setRowModesModel({});
        await sleep(5000);
        const body = {
          [API_MONTHLY.FORECAST_TYPE_CD]: form?.forecastTypeCd,
          [API_MONTHLY.MODE_CD]: form?.modeCd ? modeList.find(v => v.cd === form?.modeCd).value : "",
          [API_MONTHLY.FORECAST_MONTH]: form?.forecastMonth,
          [API_MONTHLY.DATA_TYPE_CD]: form.dataTypeCd,
          [API_MONTHLY.UPLOAD_STATUS_CD]: form.uploadStatusCd,
          [API_MONTHLY.UPLOAD_COMPANY_ABBR]: form?.companyAbbr,
          [PAGINATION.PAGE_NUMBER]: pageNumber,
        };

        const { searchData, data } = await getSearch(body);

        stopLoading();
        setPagination(searchData?.result?.pagination ?? {});
        setPageNumber(searchData?.result?.pagination?.pageNumber);
        setRows(data);
      }

      return updatedRow;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleRowDisableSelect = params => {
    if (params?.row.dataType === MONTHLY_DATA_TYPE.ARMC_SOURCE_DATA) {
      return false;
    }
    if (params?.row.uploadStatus !== UPLOAD_STATUS.PROCESSING) {
      return true;
    }
  };

  return (
    <Fragment>
      {onSearch && (
        <DataGridTable
          mode={mode}
          onSearch={onSearch}
          setMode={setMode}
          rows={rows}
          isMultipleSelection={false}
          checkboxSelection={true}
          rowHeight={45}
          autoHeight={true}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          handleRowDisableSelect={handleRowDisableSelect}
          onCellClick={false}
          rowSelection={{
            rowModesModel: rowModesModel,
            setRowModesModel: setRowModesModel,
            rowSelectionModel: rowSelectionModel,
            setRowSelectionModel: setRowSelectionModel,
          }}
          pagination={{
            pagination: pagination,
            pageNumber: pageNumber,
            handleChangePagination: handleChangePagination,
          }}
          column={{
            columns: columns,
            columnGroupingModel: null,
            columnVisibilityModel: {
              [API_MONTHLY.FILE_UPLOAD_ID]: false,
              [API_MONTHLY.MODE_CD]: false,
              [API_MONTHLY.DATA_TYPE_CD]: false,
              [API_MONTHLY.API_ID]: false,
              [API_MONTHLY.MODULE_ID]: false,
              [API_MONTHLY.FUNCTION_ID]: false,
              [API_MONTHLY.D_HODTCRE]: false,
            },
          }}
        />
      )}
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        functionId={"WDN92010"}
      />
    </Fragment>
  );
}
