import React, { useCallback, useState, useEffect } from "react";
import { Box, IconButton, Popover, TextField, styled } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

import { useGridApiContext } from "@mui/x-data-grid";
// Utils
import { isNull } from "lodash";
import { getLocalDate, getLocalDateForValidate } from "utils/init-config-date";
const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `2px solid black`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "4px 8px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
}));
const StyledDateCalendar = styled(DateCalendar)(({ theme, error }) => ({
  "& .MuiButtonBase-root": {
    "&.MuiPickersDay-root:hover": {
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.tertiary.chip}`,
    color: "#000000",
  },
}));
export default function EditableDatePicker({
  value = null,
  field,
  id,
  format = "DD/MM/YYYY",
  mask = "DD/MM/YYYY",
  views,
  fullWidth,
  maxDate,
  minDate,
  disabled = false,
  autoFocus = true,
  required = false,
  disablePast = false,
  disableKey = false,
  onChange = null,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [select, setSelect] = React.useState(getLocalDate());
  const [date, setDate] = React.useState(value);
  const [currentView, setCurrentView] = useState();

  const open = Boolean(anchorEl);
  const apiRef = useGridApiContext();

  const handleClick = event => {
    if (views?.length === 3 || !views) setCurrentView("day");
    else setCurrentView(views[0]);
    if (disabled) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") {
        setAnchorEl(null);
        return;
      }

      switch (views?.length) {
        case 1:
          currentView === "month" && setAnchorEl(null);
          break;
        case 2:
          currentView === "year" && setAnchorEl(null);
          break;
        case 3:
          currentView === "day" && setAnchorEl(null);
          break;
        default:
          currentView === "day" && setAnchorEl(null);
          break;
      }
    },
    [currentView, views?.length]
  );

  const handleSelect = value => {
    try {
      setSelect(value);
      setDate(getLocalDate(value).format(format));
      apiRef.current.setEditCellValue({
        id: id,
        field: field,
        value: getLocalDate(value).format(format),
      });
      if (!isNull(onChange)) {
        onChange(getLocalDate(value).format(format));
      }
      handleClose();
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const onBlur = event => {
    try {
      const newValue = event.target.value;
      if (!newValue) {
        setDate("");
        setSelect(getLocalDate());
        apiRef.current.setEditCellValue({ id: id, field: field, value: "" });

        if (!isNull(onChange)) {
          onChange(newValue);
        }
        return;
      }
      setDate(newValue);
      apiRef.current.setEditCellValue({ id: id, field: field, value: date });

      if (!isNull(onChange)) {
        onChange(newValue);
      }
      if (!getLocalDate(newValue, format, true).isValid()) {
        setSelect(getLocalDate());
        return;
      }
      setSelect(getLocalDate(newValue, format));
      return;
    } catch (error) {
      return false;
    }
  };

  const handleChange = event => {
    try {
      const newValue = event.target.value;
      if (!newValue) {
        setDate("");
        setSelect(getLocalDate());
        return;
      }
      setDate(newValue);

      if (!getLocalDateForValidate(newValue, format, true)) {
        setSelect(getLocalDate());
        return;
      }
      setSelect(getLocalDate(newValue, format));
      return;
    } catch (error) {
      return false;
    }
  };

  const handleViewChange = view => {
    setCurrentView(view);
  };

  const formattedValue = date => {
    try {
      // date equal null
      if (!date) {
        return "";
      }
      if (typeof date === "string") {
        return getLocalDate(date, format);
      }

      return date;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    switch (views?.length) {
      case 1:
        setCurrentView("month");
        break;
      case 2:
        setCurrentView("year");
        break;
      case 3:
        setCurrentView("day");
        break;
      default:
        setCurrentView("day");
        break;
    }
  }, []);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <Box
      width={"90%"}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "row wrap",
        alignItems: "center",
      }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputMask
          data-testid={`${id}-${field}`}
          mask={mask}
          formatChars={{
            Y: "[0-9]",
            M: "[0-9]",
            D: "[0-9]",
          }}
          value={date}
          required={required}
          disabled={disabled || disableKey}
          onChange={handleChange}
          onBlur={onBlur}
          maskChar={null}
          alwaysShowMask={false}
          autoFocus={autoFocus}
          placeholder={format}>
          {inputProps => <StyledTextField disabled={disabled || disableKey} {...inputProps} />}
        </InputMask>
        <IconButton onClick={handleClick}>
          <InsertInvitationIcon sx={{ color: "#58595B" }} />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <StyledDateCalendar
            value={formattedValue(select)}
            onChange={handleSelect}
            onViewChange={handleViewChange}
            disablePast={disablePast}
          />
        </Popover>
      </LocalizationProvider>
    </Box>
  );
}
